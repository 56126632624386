<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Dropdown from "primevue/dropdown";
import { dateFormate } from "../tool/formate";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import EmployerView from "./Employer.vue";
import Chip from "primevue/chip";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Toast from "primevue/toast";
import User from "../api/salary/auth/user";
import AdminShift from "../api/salary/admin/shift";
import AdminEmployer from "../api/salary/admin/employer";
import AdminEmployerShift from "../api/salary/admin/employer_shift_relation";
import { useI18n } from "../i18nPlugin";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/core";

const fullCalendar = ref();
const op = ref();
let userApi = new User();
let admin_shiftAPI = new AdminShift();
let admin_employerAPI = new AdminEmployer();
let admin_employer_shiftAPI = new AdminEmployerShift();

const i18n = useI18n();
const toast = useToast();
const store = useStore();

const data = reactive({
  date_confirm_by_date: null,
  date_confirm: null,
  search_date: new Date(),
  AddShiftSearch: "",
  selectShiftList: [],
  fastDialog: false,
  shift_contents: [],
  shift_content: null,
  shift_content_title: "",
  options: {
    locale: "zh-tw",
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    // initialDate:
    //   new Date().getFullYear() +
    //   "-" +
    //   (new Date().getMonth() + 1) +
    //   "-" +
    //   new Date().getDate(),
    dateClick: handleDateClick,
    customButtons: {
      prev: {
        // this overrides the prev button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.prev();
          //   console.log(
          //     fullCalendar.value.calendar.currentData.dateProfile.currentDate
          //   );
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
      next: {
        // this overrides the next button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.next();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
    },
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth",
      //   ,timeGridWeek,timeGridDay
    },
    buttonText: {
      month: "月",
      week: "週",
      day: "日",
    },
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: null,
  },
});
const beforemounted = onBeforeMount(async () => {});

function handleDateClick(arg) {
  data.shift_content_title = arg.dateStr;
  data.shift_contents = [];
  if (data.options.events != null)
    for (let i = 0; i < data.options.events.length; i++) {
      if (
        dateFormate(data.options.events[i].start, "YYYY-MM-DD") ==
        dateFormate(arg.dateStr, "YYYY-MM-DD")
      ) {
        data.shift_contents.push(data.options.events[i]);
      }
    }
  data.AddShiftSearch = "";
  data.chooseShiftDialog = true;
  op.value.hide();
}

function getShift() {
  let tmp_ids = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp_ids.push(store.state.bouns_employer[i].id);
  }
  let start = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth(),
    1,
    0,
    0
  );
  let end = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth() + 1,
    0,
    23,
    59,
    59
  );

  admin_employer_shiftAPI
    .getEmployerShiftList(tmp_ids, start.toISOString(), end.toISOString())
    .then((res) => {
      if (res.data.error_code == 0) {
        let tmp_data = res.data.data.datas;
        let tmp = [];
        if (tmp_data != null)
          for (let i = 0; i < tmp_data.length; i++) {
            for (
              let j = 0;
              j < tmp_data[i].employer_shift_relations.length;
              j++
            ) {
              let tmp_date = new Date(
                tmp_data[i].employer_shift_relations[j].date
              );
              let tmp_start = new Date(
                tmp_data[i].employer_shift_relations[j].shift.start_time
              );
              let tmp_end = new Date(
                tmp_data[i].employer_shift_relations[j].shift.end_time
              );
              let start_date = new Date(
                tmp_date.getFullYear(),
                tmp_date.getMonth(),
                tmp_date.getDate(),
                tmp_start.getHours(),
                tmp_start.getMinutes()
              );
              let end_date = new Date(
                tmp_date.getFullYear(),
                tmp_date.getMonth(),
                tmp_date.getDate(),
                tmp_end.getHours(),
                tmp_end.getMinutes()
              );

              tmp.push({
                //   id: tmp_data[i].employer_shift_relations[j].shift_id,
                shift_id: tmp_data[i].employer_shift_relations[j].shift_id,
                title:
                  tmp_data[i].employer_shift_relations[j].shift.name +
                  "(" +
                  tmp_data[i].employer_name +
                  ")",

                //   start: tmp_data[i].employer_shift_relations[j].shift.start_time,
                start: start_date,
                //   end: tmp_data[i].employer_shift_relations[j].shift.end_time,
                end: end_date,
                employer_id: tmp_data[i].employer_id,
                status: tmp_data[i].employer_shift_relations[j].status,
                stage: tmp_data[i].employer_shift_relations[j].stage,
              });
            }
          }
        data.options.events = tmp;
        // data.events = tmp;
        console.log(data.options.events);
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function toggle(event, arg) {
  data.chooseShiftDialog = false;
  console.log(arg);
  data.shift_content = arg;
  op.value.toggle(event);
}

function ChooseEmployer() {
  data.chooseDialog = true;
  store.commit("set_employer_search", "apply_bouns");
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}

const mounted = onMounted(async () => {
  CheckUser();
  //   data.options.events = [
  //     {
  //       id: 1,
  //       title: "早班" + "(" + "陳亮宇" + ")",
  //       start: "2023-02-12T10:30:00",
  //       end: "2023-02-12T12:30:00",
  //       employer_id: 1,
  //       status: 1,
  //     },
  //   ];
  getShiftSelectList();
  getShift();
});
function getShiftSelectList() {
  admin_shiftAPI.getShiftSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectShiftList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function FastSetting() {
  data.fastDialog = true;
  data.set_date = null;
  data.AddShiftSearch = "";
}
function SaveShift() {
  let tmp = [];
  console.log(store.state.bouns_employer);
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp.push({
      employer_id: store.state.bouns_employer[i].id,
      new_shifts: [
        {
          employer_id: store.state.bouns_employer[i].id,
          shift_id:
            data.AddShiftSearch != "" && data.AddShiftSearch != null
              ? data.AddShiftSearch.id
              : 0,
          date: new Date(data.shift_content_title).toISOString(),
          status: 1,
          stage: 1,
        },
      ],
      delete_shift_ids: [],
      delete_shift_dates: [],
    });
  }

  admin_employer_shiftAPI.editEmployerShift(tmp).then((res) => {
    if (res.data.error_code == 0) {
      getShift();
      data.chooseShiftDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "設定成功",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function SaveFastShift() {
  console.log(data.set_date);
  let tmp = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    if (data.set_date != null)
      for (let j = 0; j < data.set_date.length; j++) {
        tmp.push({
          employer_id: store.state.bouns_employer[i].id,
          new_shifts: [
            {
              employer_id: store.state.bouns_employer[i].id,
              shift_id:
                data.AddShiftSearch != "" && data.AddShiftSearch != null
                  ? data.AddShiftSearch.id
                  : 0,
              date: data.set_date[j].toISOString(),
              status: 1,
              stage: 1,
            },
          ],
          delete_shift_ids: [],
          delete_shift_dates: [],
        });
      }
  }

  admin_employer_shiftAPI.editEmployerShift(tmp).then((res) => {
    if (res.data.error_code == 0) {
      getShift();
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "設定成功",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function Remove(shift, check) {
  admin_employer_shiftAPI
    .editEmployerShift([
      {
        employer_id: check
          ? shift.employer_id
          : shift.extendedProps.employer_id,
        new_shifts: [],
        delete_shift_ids: [
          check ? shift.shift_id : shift.extendedProps.shift_id,
        ],
        delete_shift_dates: [check ? shift.start : shift.start],
      },
    ])
    .then((res) => {
      if (res.data.error_code == 0) {
        getShift();
        data.chooseShiftDialog = false;
        data.deleteDialog = false;
        op.value.hide();
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "刪除成功",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

watch(
  () => store.state.bouns_employer,
  () => {
    getShift();
  },
  { deep: true }
);
watch(
  () => data.search_date,
  () => {
    getShift();
  }
);
function SettingConfirm(
  check_by_date,
  status,
  stage,
  employer_ids,
  shift_ids,
  shift_date
) {
  switch (check_by_date) {
    case 1:
      if (data.date_confirm_by_date != null) {
        let start = new Date(
          data.date_confirm_by_date.getFullYear(),
          data.date_confirm_by_date.getMonth(),
          data.date_confirm_by_date.getDate(),
          0,
          0
        );
        let end = new Date(
          data.date_confirm_by_date.getFullYear(),
          data.date_confirm_by_date.getMonth(),
          data.date_confirm_by_date.getDate(),
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
    case 2:
      if (data.date_confirm != null) {
        let start = new Date(
          data.date_confirm.getFullYear(),
          data.date_confirm.getMonth(),
          1,
          0,
          0
        );
        let end = new Date(
          data.date_confirm.getFullYear(),
          data.date_confirm.getMonth() + 1,
          0,
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
    case 3:
      if (shift_date != "") {
        let start = new Date(
          shift_date.getFullYear(),
          shift_date.getMonth(),
          shift_date.getDate(),
          0,
          0
        );
        let end = new Date(
          shift_date.getFullYear(),
          shift_date.getMonth(),
          shift_date.getDate(),
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              data.chooseShiftDialog = false;
              op.value.hide();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-2">
    <div
      :style="store.state.shift_search == 'shift' ? 'margin-top: 90px' : ''"
      class="p-text-left"
    >
      <div
        v-if="store.state.shift_search == 'shift'"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").Employer }}
      </div>
      <div v-if="store.state.shift_search == 'shift'" class="p-col-12">
        <Button
          :label="i18n.$t('EmployerShift').SelectEmployer"
          class="p-mr-2 p-button-sm"
          @click="ChooseEmployer"
        />
        <span style="line-height: 35px">
          <Chip
            :key="index"
            :label="employer.id + ':' + employer.name"
            v-for="(employer, index) in store.state.bouns_employer"
          />
        </span>
      </div>
      <div
        v-if="store.state.shift_search == 'shift'"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("Employer").Fast }}
      </div>
      <div v-if="store.state.shift_search == 'shift'" class="p-col-12">
        <Button
          :label="i18n.$t('EmployerShift').Setting"
          class="p-mr-2 p-button-sm"
          @click="FastSetting"
        />
      </div>
      <div
        v-if="store.state.shift_search == 'shift'"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").EnabledDate }}
      </div>
      <div v-if="store.state.shift_search == 'shift'" class="p-col-12">
        <Calendar
          inputId="monthpicker"
          v-model="data.date_confirm_by_date"
          dateFormat="mm/yy/dd"
          :showButtonBar="true"
        />
        <Button
          :label="i18n.$t('EmployerShift').Confirm"
          class="p-button-info p-ml-2 p-button-sm"
          @click="SettingConfirm(1, 2, 1, [], [], '')"
        />
        <Button
          :label="i18n.$t('EmployerShift').ClientConfirm"
          class="p-ml-2 p-button-sm"
          @click="SettingConfirm(1, 2, 2, [], [], '')"
        />
        <Button
          :label="i18n.$t('EmployerShift').ClientClose"
          class="p-button-danger p-ml-2 p-button-sm"
          @click="SettingConfirm(1, 1, 1, [], [], '')"
        />
      </div>
      <div
        v-if="store.state.shift_search == 'shift'"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").Enabled }}
      </div>
      <div v-if="store.state.shift_search == 'shift'" class="p-col-12">
        <Calendar
          inputId="monthpicker"
          v-model="data.date_confirm"
          view="month"
          dateFormat="mm/yy"
          :showButtonBar="true"
        />
        <Button
          :label="i18n.$t('EmployerShift').Confirm"
          class="p-button-info p-ml-2 p-button-sm"
          @click="SettingConfirm(2, 2, 1, [], [], '')"
        />
        <Button
          :label="i18n.$t('EmployerShift').ClientConfirm"
          class="p-ml-2 p-button-sm"
          @click="SettingConfirm(2, 2, 2, [], [], '')"
        />
        <Button
          :label="i18n.$t('EmployerShift').ClientClose"
          class="p-button-danger p-ml-2 p-button-sm"
          @click="SettingConfirm(2, 1, 1, [], [], '')"
        />
      </div>
      <div style="background: lightgray" class="p-col-12">
        {{ i18n.$t("EmployerShift").Shift }}
      </div>
      <div class="p-col-12">
        <FullCalendar
          ref="fullCalendar"
          :events="data.events"
          :options="data.options"
          aria-haspopup="true"
          aria-controls="overlay_panel"
        >
          <template v-slot:eventContent="arg">
            <b
              :style="
                arg.event.extendedProps.status == 1
                  ? 'Color:red'
                  : arg.event.extendedProps.status == 2 &&
                    arg.event.extendedProps.stage == 2
                  ? 'Color:green'
                  : arg.event.extendedProps.status == 2 &&
                    arg.event.extendedProps.stage == 1
                  ? 'Color:blue'
                  : 'Color:black'
              "
              @click="toggle($event, arg.event)"
              aria-haspopup="true"
              aria-controls="overlay_panel"
            >
              {{ dateFormate(arg.event.start, "hh:mm") }}-
              {{ dateFormate(arg.event.end, "hh:mm") }}
              {{ arg.event.title }}</b
            >
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
  <Sidebar
    v-model:visible="data.chooseDialog"
    style="width: 70%; min-width: 375px"
    position="right"
  >
    <div style="margin-top: -80px">
      <EmployerView />
    </div>
  </Sidebar>
  <Sidebar
    v-model:visible="data.fastDialog"
    style="width: 375px; min-width: 375px"
    position="left"
  >
    <div class="p-grid">
      <div class="p-col-12">
        <Calendar
          inputId="multiple"
          v-model="data.set_date"
          selectionMode="multiple"
          :manualInput="false"
          :showButtonBar="true"
        />
      </div>
      <div class="p-col-10" style="vertical-align: middle">
        <Dropdown
          style="width: 100%"
          v-model="data.AddShiftSearch"
          :options="data.selectShiftList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').Title"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-col-2">
        <Button
          icon="pi pi-plus"
          class="p-button-rounded p-button-success p-button-sm"
          @click="SaveFastShift"
        />
      </div>
    </div>
  </Sidebar>
  <Dialog
    v-model:visible="data.chooseShiftDialog"
    header="班別設定"
    style="width: 600px"
  >
    日期：{{ dateFormate(data.shift_content_title, "YYYY-MM-DD") }}<br />
    <div>
      <div class="p-grid">
        <div class="p-col-4">班別</div>
        <div class="p-col-4">時間</div>
        <div class="p-col-4"></div>
      </div>
      <div style="overflow: scroll; height: 200px">
        <div
          :key="index"
          v-for="(shift, index) in data.shift_contents"
          class="p-grid"
          :style="
            shift.status == 1
              ? 'Color:red;'
              : shift.status == 2 && shift.stage == 2
              ? 'Color:green'
              : shift.status == 2 && shift.stage == 1
              ? 'Color:blue'
              : 'Color:black'
          "
        >
          <div
            class="p-col-4"
            style="vertical-align: middle; line-height: 40px"
          >
            {{ shift.title }}
          </div>
          <div
            class="p-col-4"
            style="vertical-align: middle; line-height: 40px"
          >
            {{ dateFormate(shift.start, "hh:mm") }}-{{
              dateFormate(shift.end, "hh:mm")
            }}
          </div>
          <div class="p-col-4">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              @click="
                () => {
                  data.shift = shift;
                  data.deleteDialog = true;
                  data.check_remove = true;
                }
              "
            />
            <Button
              icon="pi pi-play"
              class="p-button-rounded p-button-info p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  2,
                  1,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            />
            <Button
              icon="pi pi-play"
              class="p-button-rounded p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  2,
                  2,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            />
            <Button
              icon="pi pi-stop"
              class="p-button-rounded p-button-danger p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  1,
                  1,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-10" style="vertical-align: middle">
          <Dropdown
            style="width: 100%"
            v-model="data.AddShiftSearch"
            :options="data.selectShiftList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Shift').Title"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-2">
          <Button
            icon="pi pi-plus"
            class="p-button-rounded p-button-success p-button-sm"
            @click="SaveShift"
          />
        </div>
      </div>
    </div>
  </Dialog>
  <OverlayPanel
    ref="op"
    appendTo="body"
    :showCloseIcon="true"
    id="overlay_panel"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <h3>{{ data.shift_content.title }}</h3>
    日期：{{ dateFormate(data.shift_content.start, "YYYY-MM-DD") }}<br />
    開始時間：{{ dateFormate(data.shift_content.start, "hh:mm") }}<br />
    結束時間：{{ dateFormate(data.shift_content.end, "hh:mm") }}<br />
    <div class="p-mt-3" style="width: 100%; text-align: right">
      <Button
        class="p-button-danger p-button-sm"
        :label="i18n.$t('Employer').EmployerTable.Delete"
        @click="
          () => {
            data.deleteDialog = true;
            data.check_remove = false;
          }
        "
      />
      <Button
        :label="i18n.$t('EmployerShift').Confirm"
        class="p-button-info p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            2,
            1,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      />
      <Button
        :label="i18n.$t('EmployerShift').ClientConfirm"
        class="p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            2,
            2,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      />
      <Button
        :label="i18n.$t('EmployerShift').ClientClose"
        class="p-button-danger p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            1,
            1,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      />
    </div>
  </OverlayPanel>
  <!-- 單一刪除視窗 -->
  <Dialog
    v-model:visible="data.deleteDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('Shift').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="data.shift_content"
        >{{ i18n.$t("Shift").Confirm.Content }}
        <b>{{ data.shift_content.title }}</b
        >?</span
      >
      <span v-else
        >{{ i18n.$t("Shift").Confirm.Content }} <b>{{ data.shift.title }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.deleteDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="
          Remove(
            data.check_remove ? data.shift : data.shift_content,
            data.check_remove
          )
        "
      />
    </template>
  </Dialog>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
