import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerShift {
  getEmployerShiftList(employer_ids, start_date, end_date, status) {
    return axios.get(
      `/api/admin/employer-shift-relations`,
      {
        params: {
          employer_ids: employer_ids,
          start_date: start_date,
          end_date: end_date,
          status: status,
        },
      },
      config
    );
  }
  editEmployerShiftStatus(
    start_date,
    end_date,
    employer_ids,
    shift_ids,
    status,
    stage
  ) {
    return axios.put(
      `/api/admin/employer-shift-relations/status/`,
      {
        start_date: start_date,
        end_date: end_date,
        employer_ids: employer_ids,
        shift_ids: shift_ids,
        status: status,
        stage: stage,
      },
      config
    );
  }
  editEmployerShift(datas) {
    return axios.put(
      `/api/admin/employer-shift-relations/`,
      {
        datas: datas,
      },
      config
    );
  }
}
