<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Avatar from "primevue/avatar";
import FileUpload from "primevue/fileUpload";
import Info from "./Info.vue";
import router from "../router";
import * as XLSX from "xlsx/xlsx.mjs";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Chip from "primevue/chip";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Button from "primevue/button";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "../i18nPlugin";
import { useStore } from "vuex";
import AdminEmployer from "../api/salary/admin/employer";
import AdminService from "../api/salary/admin/service";
import Calendar from "primevue/calendar";
import User from "../api/salary/auth/user";
import { dateFormate } from "../tool/formate";
import { getBase64 } from "../tool/image";

let userApi = new User();
let admin_employerAPI = new AdminEmployer();
let admin_serviceAPI = new AdminService();

const store = useStore();
const i18n = useI18n();
const toast = useToast();

const data = reactive({
  excel_employer: { data: null },
  selectStatusList: [
    {
      id: 2,
      name: i18n.$t("Employer").EmployerTable.EnableAuth,
    },
    {
      id: 3,
      name: i18n.$t("Employer").EmployerTable.FreezeAuth,
    },
  ],
  selectRoleList: [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 3,
      name: "Employer",
    },
  ],
  add_employer: {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: [],
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
  },
  AddEmpolyerServicesSearch: "",
  selectServicesList: [],
  loading: false,
  employers: [],
  employer: null,
  detailDialog: false,
  addDialog: false,
  page: 1,
  page_num: 8,
  employerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  if (store.state.user.role == 1) {
    getEmployerList();
    getServiceSelectList();
  }
});

function onUploadImage(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_employer.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function getServiceSelectList() {
  admin_serviceAPI.getServiceSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectServicesList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}

function AddEmployer() {
  data.addDialog = true;
  data.AddEmpolyerRoleSearch = "";
  data.AddEmpolyerOrganisationSearch = "";
  data.AddEmpolyerPositionsSearch = [];
  data.AddEmpolyerWorkPermitsSearch = [];
  data.add_employer = {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: [],
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
  };
}

function saveAddEmployer() {
  // if (data.add_employer.password == "") {
  //   toast.add({
  //     severity: "warn",
  //     summary: "Warn Message",
  //     detail:
  //       i18n.$t("Employer").Fill +
  //       i18n.$t("Employer").EmployerTable.PassWord.Show,
  //     life: 3000,
  //   });
  // } else
  if (data.add_employer.name == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill + i18n.$t("Employer").EmployerTable.Name.Show,
      life: 3000,
    });
  } else if (data.add_employer.english_name == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.EnglishName.Show,
      life: 3000,
    });
  } else if (data.add_employer.position_name == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.PositionName.Show,
      life: 3000,
    });
  } else if (data.add_employer.role == 0 || data.add_employer.role == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill + i18n.$t("Employer").EmployerTable.Role.Show,
      life: 3000,
    });
  } else {
    admin_employerAPI
      .addEmployer(
        // data.add_employer.password,
        "0000",
        data.add_employer.position_name,
        data.add_employer.name,
        data.add_employer.english_name,
        data.add_employer.role,
        data.add_employer.image,
        data.add_employer.new_service_ids
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Employer").AddSuccess,
            life: 3000,
          });

          data.loading = true;
          getEmployerList();
          data.addDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}
function FireDialog(employer) {
  data.fireDialog = true;
  data.employer = employer;
}
function ReFireDialog(employer) {
  data.refireDialog = true;
  data.employer = employer;
}
function Fire() {
  admin_employerAPI.editEmployerStatus(data.employer.id, 3).then((res) => {
    if (res.data.error_code == 0) {
      data.loading = false;
      data.page = 1;
      data.employers = [];
      getEmployerList();
      data.fireDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Employer").FireSuccess,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function ReFire() {
  admin_employerAPI.editEmployerStatus(data.employer.id, 2).then((res) => {
    if (res.data.error_code == 0) {
      data.loading = false;
      data.page = 1;
      data.employers = [];
      getEmployerList();
      data.refireDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Employer").ReFireSuccess,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function Detail(employer) {
  store.commit("set_employer", employer);
  data.detailDialog = true;
}
function getEmployerList() {
  CheckUser();
  admin_employerAPI
    .getEmployerList(
      data.employerFilters.id.value,
      data.employerFilters.name.value,
      store.state.employer_search == "apply_bouns"
        ? 2
        : data.employerFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        // data.employers = res.data.data.datas;
        for (let i = 0; i < res.data.data.datas.length; i++) {
          if (!data.employers.find((e) => e.id === res.data.data.datas[i].id)) {
            data.employers.push(res.data.data.datas[i]);
          }
        }

        // data.employers = data.employers.concat(res.data.data.datas);
        data.page = res.data.data.page;
        data.loading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        data.totalRecords = 0;
        data.loading = false;
      }
    });
}

function handleScroll(e) {
  if (
    e.target.clientHeight + Math.ceil(e.target.scrollTop) >=
      e.target.scrollHeight &&
    data.page < Math.ceil(data.totalRecords / data.page_num)
  ) {
    data.loading = true;
    data.page = data.page + 1;
    getEmployerList();
  }
}
watch(
  () => data.AddEmpolyerRoleSearch,
  () => {
    data.add_employer.role =
      data.AddEmpolyerRoleSearch == null ? "" : data.AddEmpolyerRoleSearch.id;
  }
);
watch(
  () => data.detailDialog,
  () => {
    if (data.detailDialog == false) {
      data.loading = true;
      data.page = 1;
      data.employers = [];
      getEmployerList();
    }
  }
);

watch(
  () => data.employerFilters.id.value,
  () => {
    data.loading = true;
    data.page = 1;
    data.employers = [];
    getEmployerList();
  }
);

watch(
  () => data.employerFilters.name.value,
  () => {
    data.loading = true;
    data.page = 1;
    data.employers = [];
    getEmployerList();
  }
);

watch(
  () => data.AddEmpolyerServicesSearch,
  async () => {
    let tmpIDs = [];
    if (data.AddEmpolyerServicesSearch != null)
      for (let i = 0; i < data.AddEmpolyerServicesSearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerServicesSearch[i].id);
      }

    data.add_employer.new_service_ids = tmpIDs;
  }
);
function ChooseBouns(employer) {
  if (store.state.bouns_employer.map((e) => e.id).indexOf(employer.id) == -1) {
    store.state.bouns_employer.push(employer);
  }
}
function RemoveChooseBouns(employer) {
  console.log(employer);
  console.log(store.state.bouns_employer);
  let tmp_index;
  store.state.bouns_employer.map((e, index) => {
    if (e.id == employer.id) {
      store.state.bouns_employer.splice(index, 1);
    }
  });
}
watch(
  () => data.StatusSearch,
  () => {
    if (data.StatusSearch != null && data.StatusSearch != "") {
      data.employerFilters.status.value = data.StatusSearch.id;
    } else {
      data.employerFilters.status.value = 0;
    }
    data.loading = true;
    data.page = 1;
    data.employers = [];
    getEmployerList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 80px">
      <Accordion class="accordion-custom p-col-12" :activeIndex="0">
        <AccordionTab>
          <template #header>
            <i class="pi pi-search p-mr-1"></i>
            <span>{{ i18n.$t("Employer").EmployerTable.Search }}</span>
          </template>
          <p style="text-align: left">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText
                style="width: 150px"
                v-model="
                  data.employerFilters[
                    i18n.$t('Employer').EmployerTable.ID.Field
                  ].value
                "
                :placeholder="i18n.$t('Employer').EmployerTable.ID.Show"
              />
            </span>
            <span class="p-input-icon-left p-ml-1">
              <i class="pi pi-search" />
              <InputText
                style="width: 150px"
                v-model="
                  data.employerFilters[
                    i18n.$t('Employer').EmployerTable.Name.Field
                  ].value
                "
                :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
              />
            </span>
            <span
              v-if="store.state.employer_search != 'apply_bouns'"
              class="p-input-icon-left p-ml-1"
            >
              <i class="pi pi-search" />
              <Dropdown
                class="p-ml-1"
                v-model="data.StatusSearch"
                :options="data.selectStatusList"
                optionLabel="name"
                :filter="true"
                :placeholder="i18n.$t('Employer').EmployerTable.Status.Show"
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </span>
          </p>
          <p style="text-align: right" v-if="store.state.user.role == 1">
            <Button
              v-if="store.state.employer_search == 'employer'"
              :label="i18n.$t('Employer').EmployerTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="AddEmployer"
            />
          </p>
        </AccordionTab>
      </Accordion>
      <div
        class="p-grid"
        style="height: calc(100vh - 240px); overflow: auto"
        @scroll="handleScroll"
      >
        <div
          :key="index"
          v-for="(employer, index) in data.employers"
          class="p-col-12 p-md-4 p-lg-3"
        >
          <div
            style="height: 25px; width: 100%; background-color: CornflowerBlue"
          >
            &nbsp;
          </div>
          <Card style="width: 100%" class="p-text-left">
            <template #content>
              <Avatar
                shape="circle"
                :image="employer.image_url"
                size="xlarge"
              />
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.ID.Show }}:</span
                >{{ employer.id }}
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Name.Show }}:</span
                >{{ employer.name }}
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{
                    i18n.$t("Employer").EmployerTable.EnglishName.Show
                  }}:</span
                >{{ employer.english_name }}
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{
                    i18n.$t("Employer").EmployerTable.PositionName.Show
                  }}:</span
                >{{ employer.position_name }}
              </p>
              <p class="p-mb-1">
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Service.Show }}:</span
                >
                <Chip
                  :key="service_idex"
                  class="p-mr-1"
                  v-for="(service, service_idex) in employer.services"
                  :label="service.name"
                />
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Status.Show }}:</span
                >{{
                  employer.status == 1
                    ? i18n.$t("Employer").EmployerTable.DisableAuth
                    : employer.status == 2
                    ? i18n.$t("Employer").EmployerTable.EnableAuth
                    : employer.status == 3
                    ? i18n.$t("Employer").EmployerTable.FreezeAuth
                    : i18n.$t("Employer").EmployerTable.UnknowAuth
                }}
              </p>
            </template>
            <template #footer>
              <div v-if="store.state.employer_search == 'employer'">
                <Button
                  class="p-button-danger"
                  icon="pi pi-times"
                  v-if="employer.status == 2 && store.state.user.role == 1"
                  :label="i18n.$t('Employer').EmployerTable.Fire"
                  @click="FireDialog(employer)"
                />
                <Button
                  class="p-button-info"
                  icon="pi pi-replay"
                  v-if="employer.status == 3 && store.state.user.role == 1"
                  :label="i18n.$t('Employer').EmployerTable.ReFire"
                  @click="ReFireDialog(employer)"
                />
                <Button
                  icon="pi pi-search"
                  :label="i18n.$t('Employer').EmployerTable.Detail"
                  style="margin-left: 0.5em"
                  @click="Detail(employer)"
                />
              </div>
              <div
                v-if="
                  store.state.employer_search == 'apply_bouns' &&
                  employer.role != 1
                "
              >
                <Button
                  v-if="
                    store.state.bouns_employer
                      .map((e) => e.id)
                      .indexOf(employer.id) == -1
                  "
                  :class="employer.status == 3 ? 'p-d-none' : 'p-button-info'"
                  icon="pi pi-check"
                  :label="i18n.$t('Employer').EmployerTable.Choose"
                  @click="ChooseBouns(employer)"
                />
                <Button
                  v-else
                  class="p-button-danger"
                  icon="pi pi-trash"
                  :label="i18n.$t('Employer').EmployerTable.Delete"
                  @click="RemoveChooseBouns(employer)"
                />
              </div>
            </template>
          </Card>
        </div>
        <div v-if="data.loading" class="p-grid p-col-12">
          <div
            :key="index"
            v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8]"
            class="p-col-12 p-md-4 p-lg-3"
          >
            <div class="custom-skeleton p-4 p-pt-4">
              <div class="p-pl-2 p-pr-2">
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
              </div>
              <div class="p-grid p-mt-3 p-pl-3 p-mb-4">
                <Skeleton
                  class="p-mr-2"
                  width="5rem"
                  height="2.5rem"
                  animation="none"
                ></Skeleton>
                <Skeleton width="5rem" height="2.5rem"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Employer').AddEmployer.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-d-none">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.PassWord.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.password"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Name.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.english_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.PositionName.Show }}</label
        >
        <InputText
          v-model.trim="data.add_employer.position_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Role.Show }}</label
        >
        <Dropdown
          v-model="data.AddEmpolyerRoleSearch"
          :options="data.selectRoleList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Role.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Service.Show
        }}</label>
        <MultiSelect
          v-model="data.AddEmpolyerServicesSearch"
          :options="data.selectServicesList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Service.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Service.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Employer').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Employer').FileLoad.cancelLabel"
          @uploader="onUploadImage"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Employer").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Employer').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddEmployer"
        />
      </template>
    </Dialog>
    <Sidebar
      style="width: 70%; min-width: 375px"
      v-model:visible="data.detailDialog"
      position="right"
    >
      <Info />
    </Sidebar>
    <!-- 復職確認視窗 -->
    <Dialog
      v-model:visible="data.fireDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').FireConfirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.employer"
          >{{ i18n.$t("Employer").FireConfirm.Content }}
          <b>{{ data.employer.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').FireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.fireDialog = false"
        />
        <Button
          :label="i18n.$t('Employer').FireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="Fire"
        />
      </template>
    </Dialog>
    <!-- 離職視窗 -->
    <Dialog
      v-model:visible="data.refireDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ReFireConfirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.employer"
          >{{ i18n.$t("Employer").ReFireConfirm.Content }}
          <b>{{ data.employer.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.refireDialog = false"
        />
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="ReFire"
        />
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
.custom-skeleton {
  border: 1px solid var(--surface-border);
  border-radius: 4px;
  ul {
    list-style: none;
  }
}
p {
  line-height: 1.2;
  margin: 0;
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}
</style>
