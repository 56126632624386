<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Dropdown from "primevue/dropdown";
import { dateFormate } from "../tool/formate";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Appointment from "../api/salary/admin/appointment";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";
import Service from "../api/salary/admin/service";
import Employer from "../api/salary/admin/employer";
import AdminLineUser from "../api/salary/admin/line_user";

let userApi = new User();
let appointmentAPI = new Appointment();
let serviceAPI = new Service();
let employerAPI = new Employer();
let admin_line_userAPI = new AdminLineUser();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  LineUserSearch: "",
  expandedRows: [],
  selectLineUserList: [],
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewingAppointmentStatus,
    },
    {
      id: 2,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewedAppointmentStatus,
    },
    {
      id: 3,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .CancelAppointmentStatus,
    },
  ],
  selectServiceList: [],
  selectEmployerList: [],
  appointment: {
    id: "",
    name: "",
    remark: "",
    start_time: "",
    end_time: "",
  },
  add_appointment: {
    id: "",
    name: "",
    remark: "",
    start_time: "",
    end_time: "",
  },
  appointment_content: [],
  appointmentLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  appointments: { data: null },
  // appointment: null,
  page: 1,
  page_num: 10,
  selectedAppointments: null,
  appointmentFilters: {
    customer_name: { value: "", matchMode: "contains" },
    less_than_start_time: { value: "", matchMode: "contains" },
    greater_than_start_time: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.appointmentLoading = true;
  if (store.state.user != null) {
    getAppointmentList();
    getServiceSelectList();
    getLineUserSelectList();
  }
});

function getLineUserSelectList() {
  admin_line_userAPI.getLineUserSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectLineUserList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getServiceSelectList() {
  serviceAPI.getServiceSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectServiceList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerSelectList() {
  // console.log(dateFormate(data.add_start_time, "YYYY-MM-DD hh:mm"));
  // console.log(data.add_start_time.toISOString());
  // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  // var localISOTime = new Date(data.add_start_time - tzoffset).toISOString();
  // var localISOTime_en = new Date(data.add_end_time - tzoffset).toISOString();
  // console.log(localISOTime);
  employerAPI
    .getEmployerFreeSelectList(
      data.add_start_time != "" && data.add_start_time != null
        ? data.add_start_time.toISOString()
        : // data.add_start_time.toISOString()
          "",
      data.add_end_time != "" && data.add_end_time != null
        ? data.add_end_time.toISOString()
        : // data.add_end_time.toISOString()
          "",
      data.add_service_id,
      2,
      ""
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectEmployerList = res.data.data.datas;
        for (let i = 0; i < data.selectEmployerList.length; i++) {
          data.selectEmployerList[i].name =
            data.selectEmployerList[i].name +
            "(編號：" +
            data.selectEmployerList[i].id +
            ")";
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function getAppointmentList() {
  CheckUser();
  appointmentAPI
    .getAppointmentList(
      data.appointmentFilters.customer_name.value,
      data.appointmentFilters.less_than_start_time.value,
      data.appointmentFilters.greater_than_start_time.value,
      data.appointmentFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.appointments.data = res.data.data.datas;
        data.page = res.data.data.page;
        data.expandedRows = [];
        data.appointmentLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.appointmentLoading = false;
      }
    });
}

function saveEditAppointment() {
  //   console.log(data.appointment.status);
  appointmentAPI
    .editAppointment(
      data.appointment.id,
      data.appointment.customer_phone,
      data.appointment.customer_name,
      data.appointment.source_of_appointment,
      data.appointment.status,
      data.appointment.start_time,
      data.appointment.remark,
      data.appointment_content
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Appointment").EditSuccess,
          life: 3000,
        });

        data.appointmentLoading = true;
        getAppointmentList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddAppointment() {
  appointmentAPI
    .addAppointment(
      data.LineUserSearch == null ? null : data.LineUserSearch.id,
      data.add_appointment.customer_phone,
      data.add_appointment.customer_name,
      1,
      1,
      data.add_appointment.start_time,
      data.add_appointment.remark,
      data.appointment_content
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Appointment").AddSuccess,
          life: 3000,
        });

        data.appointmentLoading = true;
        getAppointmentList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function findIndexes(arr, callback) {
  return arr.reduce((indexes, element, index) => {
    if (callback(element, index, arr)) {
      indexes.push(index);
    }
    return indexes;
  }, []);
}

function readFile(e) {
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = async (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 2,
    });
    let importExcel = [];

    for (let i = 0; i < json.length; i++) {
      let check_user_id = await findIndexes(
        importExcel,
        (obj) => obj.user_id === json[i].user_id
      );

      let check_customer_name = await findIndexes(
        importExcel,
        (obj) =>
          obj.customer_name === json[i].customer_name &&
          obj.customer_phone === json[i].customer_phone
      );

      if (check_user_id.length != 0) {
        for (let j = 0; j < check_user_id.length; j++) {
          if (j + 1 == check_user_id.length) {
            if (
              String(importExcel[check_user_id[j]].end_time) ===
              String(json[i].start_time)
            ) {
              await importExcel[check_user_id[j]].appointment_content.push({
                employer_id:
                  json[i].employer_id != undefined ? json[i].employer_id : "",
                service_id:
                  json[i].service_id != undefined ? json[i].service_id : "",
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
              });
              importExcel[check_user_id[j]].end_time = json[i].start_time;
            } else {
              await importExcel.push({
                user_id:
                  json[i].user_id != undefined
                    ? json[i].user_id == ""
                      ? 0
                      : parseInt(json[i].user_id)
                    : 0,
                customer_name:
                  json[i].customer_name != undefined
                    ? json[i].customer_name
                    : "",
                customer_phone:
                  json[i].customer_phone != undefined
                    ? json[i].customer_phone
                    : "",
                source_of_appointment: 1,
                status: 1,
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
                end_time: json[i].end_time != undefined ? json[i].end_time : "",
                remark: json[i].remark != undefined ? json[i].remark : "",
                appointment_content: [],
              });
              await importExcel[
                importExcel.length - 1
              ].appointment_content.push({
                employer_id:
                  json[i].employer_id != undefined ? json[i].employer_id : "",
                service_id:
                  json[i].service_id != undefined ? json[i].service_id : "",
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
              });
            }
          }
        }
      } else if (
        json[i].user_id == undefined &&
        check_customer_name.length != 0
      ) {
        for (let j = 0; j < check_customer_name.length; j++) {
          if (j + 1 == check_customer_name.length) {
            if (
              String(importExcel[check_customer_name[j]].end_time) ==
              String(json[i].start_time)
            ) {
              await importExcel[
                check_customer_name[j]
              ].appointment_content.push({
                employer_id:
                  json[i].employer_id != undefined ? json[i].employer_id : "",
                service_id:
                  json[i].service_id != undefined ? json[i].service_id : "",
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
              });
              importExcel[check_customer_name[j]].end_time = json[i].start_time;
            } else {
              await importExcel.push({
                user_id:
                  json[i].user_id != undefined
                    ? json[i].user_id == ""
                      ? 0
                      : parseInt(json[i].user_id)
                    : 0,
                customer_name:
                  json[i].customer_name != undefined
                    ? json[i].customer_name
                    : "",
                customer_phone:
                  json[i].customer_phone != undefined
                    ? json[i].customer_phone
                    : "",
                source_of_appointment: 1,
                status: 1,
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
                end_time: json[i].end_time != undefined ? json[i].end_time : "",
                remark: json[i].remark != undefined ? json[i].remark : "",
                appointment_content: [],
              });
              await importExcel[
                importExcel.length - 1
              ].appointment_content.push({
                employer_id:
                  json[i].employer_id != undefined ? json[i].employer_id : "",
                service_id:
                  json[i].service_id != undefined ? json[i].service_id : "",
                start_time:
                  json[i].start_time != undefined ? json[i].start_time : "",
              });
            }
          }
        }
      } else {
        await importExcel.push({
          user_id:
            json[i].user_id != undefined
              ? json[i].user_id == ""
                ? 0
                : parseInt(json[i].user_id)
              : 0,
          customer_name:
            json[i].customer_name != undefined ? json[i].customer_name : "",
          customer_phone:
            json[i].customer_phone != undefined ? json[i].customer_phone : "",
          source_of_appointment: 1,
          status: 1,
          start_time: json[i].start_time != undefined ? json[i].start_time : "",
          end_time: json[i].end_time != undefined ? json[i].end_time : "",
          remark: json[i].remark != undefined ? json[i].remark : "",
          appointment_content: [],
        });
        await importExcel[importExcel.length - 1].appointment_content.push({
          employer_id:
            json[i].employer_id != undefined ? json[i].employer_id : "",
          service_id: json[i].service_id != undefined ? json[i].service_id : "",
          start_time: json[i].start_time != undefined ? json[i].start_time : "",
        });
      }
    }

    appointmentAPI.batchAppointment(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Appointment").AddSuccess,
          life: 3000,
        });

        data.appointmentLoading = true;
        getAppointmentList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}

function addAppointment() {
  CheckUser();
  data.addDialog = true;
  data.add_start_time = "";
  data.add_end_time = "";
  data.add_service_id = 0;
  data.appointment_content = [];
  data.LineUserSearch = null;
  data.add_appointment = {
    id: "",
    name: "",
    remark: "",
    customer_name: "",
    customer_phone: "",
    start_time: "",
    end_time: "",
  };
  data.appointment = {
    id: "",
    name: "",
    remark: "",
    customer_name: "",
    customer_phone: "",
    start_time: "",
    end_time: "",
  };
}
function editAppointment(appointment) {
  CheckUser();
  data.appointment = {
    id: appointment.id,
    customer_name: appointment.customer_name,
    customer_phone: appointment.customer_phone,
    remark: appointment.remark,
    start_time: new Date(appointment.start_time),
    status: appointment.status,
    end_time: new Date(appointment.end_time),
    appointment_content: appointment.appointment_content,
    source_of_appointment: appointment.source_of_appointment,
  };
  //   data.appointment_content = appointment.appointment_content;
  data.appointment_content = [];
  if (appointment.appointment_content != null) {
    for (let i = 0; i < appointment.appointment_content.length; i++) {
      data.appointment_content.push({
        id: appointment.appointment_content[i].id,
        employer_id: appointment.appointment_content[i].employer_id,
        service_id: appointment.appointment_content[i].service.id,
        service_name: appointment.appointment_content[i].service.name,
        employer_name:
          appointment.appointment_content[i].employer.id == 0
            ? "不指定"
            : appointment.appointment_content[i].employer.name +
              "(" +
              "編號：" +
              appointment.appointment_content[i].employer.id +
              ")",
        remark: "",
        start_time: appointment.appointment_content[i].start_time,
        employer_list: [],
        employer: "",
      });
      if (appointment.appointment_content[i].employer_id == 0)
        employerAPI
          .getEmployerFreeSelectList(
            new Date(
              appointment.appointment_content[i].start_time
            ).toISOString(),
            new Date(appointment.appointment_content[i].end_time).toISOString(),
            appointment.appointment_content[i].service.id
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              data.appointment_content[i].employer_list = res.data.data.datas;
              for (
                let j = 0;
                j < data.appointment_content[i].employer_list.length;
                j++
              ) {
                data.appointment_content[i].employer_list[j].name =
                  data.appointment_content[i].employer_list[j].name +
                  "(編號：" +
                  data.appointment_content[i].employer_list[j].id +
                  ")";
              }
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
    }
  }

  data.editDialog = true;
  data.add_start_time = data.appointment.end_time;
  data.add_end_time = "";
  data.add_service_id = 0;
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(appointment) {
  CheckUser();
  data.appointment = appointment;
  data.deleteDialog = true;
}
function deleteAppointment() {
  appointmentAPI.deleteAppointment([data.appointment.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Appointment").DeleteSuccess,
        life: 3000,
      });

      data.appointmentLoading = true;
      getAppointmentList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedAppointment() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedAppointments.length; i++) {
    selectDelete.push(data.selectedAppointments[i].id);
  }
  appointmentAPI.deleteAppointment(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Appointment").DeleteSuccess,
        life: 3000,
      });

      data.appointmentLoading = true;
      data.selectedAppointments = [];
      getAppointmentList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.LineUserSearch,
  () => {
    if (data.LineUserSearch != "" && data.LineUserSearch != null) {
      data.add_appointment.customer_name = data.LineUserSearch.name;
      data.add_appointment.customer_phone = data.LineUserSearch.phone;
    } else {
      data.add_appointment.customer_name = "";
      data.add_appointment.customer_phone = "";
    }
  }
);
watch(
  () => data.page,
  () => {
    data.appointmentLoading = true;
    getAppointmentList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.appointmentLoading = true;
    if (data.StatusSearch != null && data.StatusSearch != "") {
      data.appointmentFilters.status.value = data.StatusSearch.id;
    } else {
      data.appointmentFilters.status.value = 0;
    }
    getAppointmentList();
  }
);
watch(
  () => data.appointmentFilters.customer_name.value,
  () => {
    data.appointmentLoading = true;
    getAppointmentList();
  }
);
watch(
  () => data.add_appointment.start_time,
  () => {
    if (
      data.add_appointment.start_time != null &&
      data.add_appointment.start_time != ""
    ) {
      data.add_start_time = data.add_appointment.start_time;
      data.add_start_time.setSeconds(0);
      data.add_start_time.setMilliseconds(0);
    } else {
      data.add_start_time = "";
    }
    data.serviceSearch = "";
    data.selectEmployerList = [];
    data.appointment_content = [];
  }
);
watch(
  () => data.appointment_content,
  () => {
    if (data.appointment_content != null && data.appointment_content != "")
      for (let i = 0; i < data.appointment_content.length; i++) {
        if (data.appointment_content[i].employer != "") {
          data.appointment_content[i].employer_id =
            data.appointment_content[i].employer.id;
        }
      }
  },
  { deep: true }
);

watch(
  () => data.appointment.start_time,
  () => {
    if (data.appointment_content.length != 0) {
      if (
        data.appointment.start_time.toISOString() !=
        new Date(data.appointment_content[0].start_time).toISOString()
      ) {
        let delete_ids = [];
        for (let i = 0; i < data.appointment_content.length; i++) {
          if (data.appointment_content[i].id != 0)
            delete_ids.push(data.appointment_content[i].id);
        }
        if (delete_ids.length != 0) {
          appointmentAPI.deleteAppointmentContent(delete_ids).then((res) => {
            if (res.data.error_code == 0) {
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: i18n.$t("Appointment").DeleteSuccess,
                life: 3000,
              });
              data.appointment_content = [];
              data.appointmentLoading = true;
              getAppointmentList();
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
        } else {
          data.add_start_time = data.appointment.start_time;
          data.appointment_content = [];
        }
      }
    }
    data.add_start_time = data.appointment.start_time;
    data.add_start_time.setSeconds(0);
    data.add_start_time.setMilliseconds(0);
    data.serviceSearch = "";
    data.selectEmployerList = [];
  }
);

watch(
  () => data.serviceSearch,
  () => {
    if (data.serviceSearch != null && data.serviceSearch != "") {
      let tmp_end = new Date(data.add_start_time);
      tmp_end.setTime(
        tmp_end.setMinutes(tmp_end.getMinutes() + data.serviceSearch.duration)
      );
      //   tmp_end.setTime(tmp_end.setMinutes(tmp_end.getMinutes() + 30));
      data.add_end_time = tmp_end;
      data.add_service_id = data.serviceSearch.id;
      getEmployerSelectList();
    } else {
      data.add_end_time = "";
      data.add_service_id = 0;
      data.selectEmployerList = [];
    }
  }
);

watch(
  () => data.start_time,
  () => {
    data.appointmentLoading = true;
    if (data.start_time != null) {
      if (data.start_time[0] != null) {
        let start = new Date(
          data.start_time[0].getFullYear(),
          data.start_time[0].getMonth(),
          data.start_time[0].getDate(),
          0,
          0
        );
        data.appointmentFilters.less_than_start_time.value =
          start.toISOString();
      }

      // data.appointmentFilters.less_than_start_time.value =
      //     data.start_time[0] != null ? data.start_time[0].toISOString() : "";
      if (data.start_time[1] != null) {
        let end = new Date(
          data.start_time[1].getFullYear(),
          data.start_time[1].getMonth(),
          data.start_time[1].getDate() + 1,
          0,
          0
        );
        data.appointmentFilters.greater_than_start_time.value =
          end.toISOString();
        //   data.appointmentFilters.greater_than_start_time.value =
        //     data.start_time[1] != null ? data.start_time[1].toISOString() : "";
        //   }
      }
    } else {
      data.appointmentFilters.less_than_start_time.value = "";
      data.appointmentFilters.greater_than_start_time.value = "";
    }

    getAppointmentList();
  }
);
function AddAppointmentContent() {
  data.add_start_time.setSeconds(0);
  data.add_start_time.setMilliseconds(0);
  data.add_end_time.setSeconds(0);
  data.add_end_time.setMilliseconds(0);
  if (data.appointment.id == "") {
    data.appointment_content.push({
      id: 0,
      employer_id: data.employerSearch.id,
      service_id: data.add_service_id,
      service_name: data.serviceSearch.name,
      employer_name: data.employerSearch.name,
      remark: "",
      start_time: data.add_start_time.toISOString(),
      employer_list: [],
      employer: "",
    });
  } else {
    data.appointment_content.push({
      id: 0,
      employer_id: data.employerSearch.id,
      service_id: data.add_service_id,
      service_name: data.serviceSearch.name,
      employer_name: data.employerSearch.name,
      appointment_id: data.appointment.id,
      remark: "",
      start_time: data.add_start_time.toISOString(),
      employer_list: [],
      employer: "",
    });
  }
  data.add_start_time = data.add_end_time;
  data.serviceSearch = "";
  data.selectEmployerList = [];
  data.employerSearch = "";
}
function removeAppoimentContent() {
  if (data.appointment_content[data.appointment_content.length - 1].id != 0) {
    appointmentAPI
      .deleteAppointmentContent([
        data.appointment_content[data.appointment_content.length - 1].id,
      ])
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Appointment").DeleteSuccess,
            life: 3000,
          });

          data.add_start_time = new Date(
            data.appointment_content[
              data.appointment_content.length - 1
            ].start_time
          );
          data.appointment_content.splice(-1, 1);
          data.serviceSearch = "";
          data.selectEmployerList = [];
          data.employerSearch = "";
          data.appointmentLoading = true;
          getAppointmentList();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } else {
    data.add_start_time = new Date(
      data.appointment_content[data.appointment_content.length - 1].start_time
    );
    data.appointment_content.splice(-1, 1);
    data.serviceSearch = "";
    data.selectEmployerList = [];
    data.employerSearch = "";
  }
}
// function onRowExpand(e) {
//   //   console.log(e.data.id);
//   getAppoimentContentList(e.data.id);
// }
// function getAppoimentContentList(id) {
//   appointmentAPI.getAppointmentInfo(id).then((res) => {
//     if (res.data.error_code == 0) {
//       console.log(res.data.data.datas);
//       //   for (let i = 0; i < data.payments.data.length; i++) {
//       //     if (data.payments.data[i].id == id) {
//       //       data.payments.data[i].contents = res.data.data.datas;
//       //       data.payment.contents = res.data.data.datas;
//       //       if (data.invoiceDialog == true) {
//       //         for (let j = 0; j < data.payment.contents.length; j++) {
//       //           data.payment.contents[j].account_item_id = {
//       //             account_type_id: 0,
//       //             account_type_name: "",
//       //             company_id: 0,
//       //             company_name: "",
//       //             description: "",
//       //             id: data.payment.contents[j].account_item_id,
//       //             name: data.payment.contents[j].account_item_name,
//       //           };
//       //           data.payment.contents[j].department_id = {
//       //             description:
//       //               data.payment.contents[j].department_description,
//       //             id: data.payment.contents[j].department_id,
//       //             name: data.payment.contents[j].department_name,
//       //           };
//       //         }
//       //       }
//       //     }
//       //   }
//     } else {
//       toast.add({
//         severity: "warn",
//         summary: "Warn Message",
//         detail: res.data.error_text,
//         life: 3000,
//       });
//     }
//   });
// }

function Confirm(show, status, appointment) {
  data.appointment = {
    id: appointment.id,
    customer_name: appointment.customer_name,
    remark: appointment.remark,
    start_time: new Date(appointment.start_time),
    end_time: new Date(appointment.end_time),
    appointment_content: appointment.appointment_content,
    source_of_appointment: appointment.source_of_appointment,
  };
  data.show = show;
  data.status = status;
  data.confirm_status_dialog = true;
}
function changeAppointmentStatus() {
  appointmentAPI
    .editAppointmentStatus(
      data.appointment.id,
      data.appointment.customer_name,
      data.appointment.source_of_appointment,
      data.status,
      data.appointment.start_time.toISOString(),
      data.appointment.remark,
      data.appointment.appointment_content
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.confirm_status_dialog = false;
        getAppointmentList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.appointments.data"
      :loading="data.appointmentLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:expandedRows="data.expandedRows"
      v-model:filters="data.appointmentFilters"
      v-model:selection="data.selectedAppointments"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("Appointment").AppointmentTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.appointmentFilters[
                        i18n.$t('Appointment').AppointmentTable.CustomerName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('Appointment').AppointmentTable.CustomerName.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Appointment').AppointmentTable.Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.start_time"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('Appointment').AppointmentTable.StartTime.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <label class="button-up p-mr-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Appointment").AppointmentTable.Excel }}
            </label>
            <Button
              :label="i18n.$t('Appointment').AppointmentTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addAppointment"
            />
            <Button
              :label="i18n.$t('Appointment').AppointmentTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedAppointments || !data.selectedAppointments.length
              "
            />
          </div>
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 1rem" />
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Appointment').AppointmentTable.ID.Field"
        :header="i18n.$t('Appointment').AppointmentTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.CustomerName.Field"
        :header="i18n.$t('Appointment').AppointmentTable.CustomerName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.CustomerPhone.Field"
        :header="i18n.$t('Appointment').AppointmentTable.CustomerPhone.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.StartTime.Field"
        :header="i18n.$t('Appointment').AppointmentTable.StartTime.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.start_time, "YYYY-MM-DD hh:mm") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.EndTime.Field"
        :header="i18n.$t('Appointment').AppointmentTable.EndTime.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.end_time, "YYYY-MM-DD hh:mm") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('Appointment').AppointmentTable.SourceOfAppointment.Field
        "
        :header="
          i18n.$t('Appointment').AppointmentTable.SourceOfAppointment.Show
        "
      >
        <template #body="slotProps">
          {{
            slotProps.data.source_of_appointment == 1
              ? i18n.$t("Appointment").AppointmentTable.SourceOfAppointmentShow
                  .Hand
              : slotProps.data.source_of_appointment == 2
              ? i18n.$t("Appointment").AppointmentTable.SourceOfAppointmentShow
                  .Line
              : i18n.$t("Appointment").AppointmentTable.SourceOfAppointmentShow
                  .Unknow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.Remark.Field"
        :header="i18n.$t('Appointment').AppointmentTable.Remark.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Appointment').AppointmentTable.Status.Field"
        :header="i18n.$t('Appointment').AppointmentTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Appointment").AppointmentTable.StatusShow
                  .ReviewingAppointmentStatus
              : slotProps.data.status == 2
              ? i18n.$t("Appointment").AppointmentTable.StatusShow
                  .ReviewedAppointmentStatus
              : slotProps.data.status == 3
              ? i18n.$t("Appointment").AppointmentTable.StatusShow
                  .CancelAppointmentStatus
              : i18n.$t("Appointment").AppointmentTable.StatusShow.Unknow
          }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            v-tooltip="i18n.$t('Appointment').ToolTip.Edit"
            v-if="slotProps.data.status == 1"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2 p-button-sm"
            @click="editAppointment(slotProps.data)"
          />
          <!-- <Button
              v-tooltip="i18n.$t('Appointment').ToolTip.Delete"
              v-if="slotProps.data.status != 2"
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning p-mr-2 p-button-sm"
              @click="confirmDelete(slotProps.data)"
            /> -->
          <Button
            v-tooltip="i18n.$t('Appointment').ToolTip.Confirm"
            v-if="slotProps.data.status == 1"
            icon="pi pi-check"
            class="p-button-rounded p-button-info p-mr-2 p-button-sm"
            @click="Confirm('確認通過', 2, slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('Appointment').ToolTip.Cancel"
            v-if="slotProps.data.status == 1"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-sm"
            @click="Confirm('確認取消', 3, slotProps.data)"
          />
          <!-- <Button
            v-tooltip="i18n.$t('Appointment').ToolTip.Rollback"
            v-if="slotProps.data.status == 3"
            icon="pi pi-replay"
            class="p-button-rounded p-button-info p-button-sm"
            @click="Confirm('確認回到待審核狀態', 1, slotProps.data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            style="width: 90vw"
            :value="slotProps.data.appointment_content"
            responsiveLayout="scroll"
          >
            <Column
              class="p-col-2"
              :field="
                i18n.$t('Appointment').AppointmentContentTable.Service.Field
              "
              :header="
                i18n.$t('Appointment').AppointmentContentTable.Service.Show
              "
            >
              <template #body="slotProps">
                {{ slotProps.data.service.name }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('Appointment').AppointmentContentTable.StartTime.Field
              "
              :header="
                i18n.$t('Appointment').AppointmentContentTable.StartTime.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.start_time, "hh:mm") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('Appointment').AppointmentContentTable.EndTime.Field
              "
              :header="
                i18n.$t('Appointment').AppointmentContentTable.EndTime.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.end_time, "hh:mm") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('Appointment').AppointmentContentTable.Employer.Field
              "
              :header="
                i18n.$t('Appointment').AppointmentContentTable.Employer.Show
              "
            >
              <template #body="slotProps">
                {{
                  slotProps.data.employer.id == 0
                    ? "不指定"
                    : slotProps.data.employer.name +
                      "(編號：" +
                      slotProps.data.employer.id +
                      ")"
                }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Appointment').AddAppointment.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.CustomerName.Show
        }}</label>
        <Dropdown
          v-model="data.LineUserSearch"
          :options="data.selectLineUserList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('Appointment').AppointmentTable.CustomerName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <br />
        <InputText
          id="name"
          v-model.trim="data.add_appointment.customer_name"
          placeholder="手動輸入新客人名稱"
          required="true"
          v-if="
            data.LineUserSearch != null && data.LineUserSearch != ''
              ? false
              : true
          "
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.CustomerPhone.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_appointment.customer_phone"
          required="true"
          :disabled="
            data.LineUserSearch != null && data.LineUserSearch != ''
              ? true
              : false
          "
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.StartTime.Show
        }}</label>
        <Calendar
          inputId="time12"
          :min-date="new Date()"
          v-model="data.add_appointment.start_time"
          :timeOnly="false"
          :showTime="true"
          :stepMinute="30"
          hourFormat="24"
        />
      </div>
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.ClassShow
        }}</label> -->
        <div class="p-grid" style="background: gray; color: white">
          <div class="p-col-3">時間</div>
          <div class="p-col-3">課程</div>
          <div class="p-col-4">老師</div>
          <div class="p-col-2"></div>
        </div>
        <div style="height: 170px; overflow: scroll">
          <div
            :key="index"
            v-for="(item, index) in data.appointment_content"
            class="p-grid p-mt-1"
            style="border-bottom: 1px solid"
          >
            <div class="p-col-3" style="padding-top: 15px">
              {{ dateFormate(item.start_time, "hh:mm") }}
            </div>
            <div class="p-col-3" style="padding-top: 15px">
              {{ item.service_name }}
            </div>
            <div class="p-col-4" style="padding-top: 15px">
              {{ item.employer_name }}
            </div>
            <div class="p-col-2">
              <Button
                v-if="index + 1 == data.appointment_content.length"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger"
                @click="removeAppoimentContent"
              />
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-1">
          <div class="p-col-3">
            <Calendar
              inputId="time12"
              v-model="data.add_start_time"
              :timeOnly="true"
              hourFormat="24"
              disabled
            />
          </div>
          <div class="p-col-3">
            <Dropdown
              v-model="data.serviceSearch"
              :options="data.selectServiceList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Service').Title"
              :showClear="true"
              :disabled="
                data.add_start_time == '' || data.add_start_time == null
              "
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-col-4">
            <Dropdown
              v-model="data.employerSearch"
              :options="data.selectEmployerList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <!-- <img :src="slotProps.value.image_url" /> -->
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-col-2">
            <Button
              :disabled="
                data.employerSearch == '' || data.employerSearch == null
              "
              icon="pi pi-check"
              class="p-button-rounded"
              @click="AddAppointmentContent"
            />
          </div>
        </div>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AddAppointment.Remark
        }}</label>
        <Textarea
          id="remark"
          v-model="data.add_appointment.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Appointment').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Appointment').Save"
          icon="pi pi-check"
          class="p-button-text"
          :disabled="
            !(
              data.add_appointment.customer_name != '' &&
              data.add_appointment.customer_phone != ''
            )
          "
          @click="saveAddAppointment"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Appointment').EditAppointment.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.CustomerName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.appointment.customer_name"
          required="true"
          :disabled="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.CustomerPhone.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.appointment.customer_phone"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.StartTime.Show
        }}</label>
        <Calendar
          :min-date="new Date()"
          inputId="time12"
          v-model="data.appointment.start_time"
          :timeOnly="false"
          :showTime="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Appointment").AppointmentTable.ClassShow
        }}</label> -->
        <div class="p-grid" style="background: gray; color: white">
          <div class="p-col-3">時間</div>
          <div class="p-col-3">課程</div>
          <div class="p-col-4">老師</div>
          <div class="p-col-2"></div>
        </div>
        <div style="height: 170px; overflow: scroll">
          <div
            :key="index"
            v-for="(item, index) in data.appointment_content"
            class="p-grid p-mt-1"
            style="border-bottom: 1px solid"
          >
            <div class="p-col-3" style="padding-top: 15px">
              {{ dateFormate(item.start_time, "hh:mm") }}
            </div>
            <div class="p-col-3" style="padding-top: 15px">
              {{ item.service_name }}
            </div>
            <div class="p-col-4" style="padding-top: 15px">
              {{ item.employer_name == "不指定" ? "" : item.employer_name }}
              <Dropdown
                v-if="item.employer_name == '不指定'"
                v-model="item.employer"
                :options="item.employer_list"
                optionLabel="name"
                :filter="true"
                :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <!-- <img :src="slotProps.value.image_url" /> -->
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-col-2">
              <Button
                v-if="index + 1 == data.appointment_content.length"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger"
                @click="removeAppoimentContent"
              />
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-1">
          <div class="p-col-3">
            <Calendar
              inputId="time12"
              v-model="data.add_start_time"
              :timeOnly="true"
              hourFormat="24"
              disabled
            />
          </div>
          <div class="p-col-3">
            <Dropdown
              v-model="data.serviceSearch"
              :options="data.selectServiceList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Service').Title"
              :showClear="true"
              :disabled="
                data.add_start_time == '' || data.add_start_time == null
              "
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-col-4">
            <Dropdown
              v-model="data.employerSearch"
              :options="data.selectEmployerList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <!-- <img :src="slotProps.value.image_url" /> -->
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-col-2">
            <Button
              :disabled="
                data.employerSearch == '' || data.employerSearch == null
              "
              icon="pi pi-check"
              class="p-button-rounded"
              @click="AddAppointmentContent"
            />
          </div>
        </div>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Appointment").EditAppointment.Remark
        }}</label>
        <Textarea
          id="remark"
          v-model="data.appointment.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Appointment').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Appointment').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditAppointment"
        />
      </template>
    </Dialog>

    <!-- 修改狀態視窗 -->
    <Dialog
      v-model:visible="data.confirm_status_dialog"
      :style="{ width: '450px' }"
      :header="'確認視窗'"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.appointment"
          >{{ data.show }} <b>訂單ID:{{ data.appointment.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Appointment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.confirm_status_dialog = false"
        />
        <Button
          :label="i18n.$t('Appointment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="changeAppointmentStatus"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Appointment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.appointment"
          >{{ i18n.$t("Appointment").Confirm.Content }}
          <b>{{ data.appointment.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Appointment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Appointment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteAppointment"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Appointment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Appointment").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Appointment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Appointment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedAppointment"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
