<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Avatar from "primevue/avatar";
import { printHTML } from "../tool/print";
import Dialog from "primevue/dialog";
import router from "../router";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Chip from "primevue/chip";
import MultiSelect from "primevue/multiselect";
import TabMenu from "primevue/tabmenu";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "../i18nPlugin";
import { useStore } from "vuex";
import User from "../api/salary/auth/user";

import AdminEmployer from "../api/salary/admin/employer";
import AdminService from "../api/salary/admin/service";
import { dateFormate } from "../tool/formate";
import { getBase64 } from "../tool/image";
import Shift from "./Shift.vue";

let userApi = new User();
let admin_employerAPI = new AdminEmployer();
let admin_serviceAPI = new AdminService();

const i18n = useI18n();
const toast = useToast();
const store = useStore();
const data = reactive({
  active: 0,
  items: [
    {
      active: 0,
      label: i18n.$t("Employer").Info,
      icon: "pi pi-fw pi-home",
    },
    {
      active: 1,
      label: i18n.$t("Employer").Shift,
      icon: "pi pi-fw pi-calendar",
    },
    // {
    //   active: 2,
    //   label: i18n.$t("Employer").Order,
    //   icon: "pi pi-fw pi-calendar",
    // },
  ],
  AddEmpolyerServicesSearch: "",
  selectServicesList: [],
  RoleChoose: "",
  RoleList: [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 3,
      name: "Employer",
    },
  ],
  employer: {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: 0,
    delete_position_ids: [],
    delete_work_permit_ids: [],
    delete_currency_id: 0,
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
  },
  totalRecords: 0,
});
const beforemounted = onBeforeMount(async () => {
  data.employer = store.state.employer;
  if (store.state.user.role == 1) {
    getServiceSelectList();
  }
});
const mounted = onMounted(async () => {
  store.commit("set_shift_search", "info");
  store.commit("set_bouns_employer", [store.state.employer]);
  CheckUser();
  for (let i = 0; i < data.RoleList.length; i++) {
    if (data.RoleList[i].id == data.employer.role) {
      data.RoleChoose = data.RoleList[i];
    }
  }
});

function getServiceSelectList() {
  admin_serviceAPI.getServiceSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectServicesList = res.data.data.datas;
      if (data.employer.services != null) {
        for (let i = 0; i < data.employer.services.length; i++) {
          data.employer.services[i] = {
            dollar: "0.00",
            duration: 0,
            id: data.employer.services[i].id,
            name: data.employer.services[i].name,
            remark: "",
          };
        }
        data.AddEmpolyerServicesSearch = data.employer.services;
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function onUploadImage(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.employer.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}

function EditEmployer() {
  admin_employerAPI
    .editEmployerPassword(store.state.employer.id, data.employer.password)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "Success",
          summary: "Success Message",
          detail: i18n.$t("Employer").EditSuccess,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function AddEmployer() {
  admin_employerAPI
    .editEmployer(
      store.state.employer.id,
      data.employer.position_name,
      data.employer.name,
      data.employer.english_name,
      data.employer.role,
      data.employer.image,
      data.employer.new_service_ids,
      data.employer.delete_service_ids
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "Success",
          summary: "Success Message",
          detail: i18n.$t("Employer").EditSuccess,
          life: 3000,
        });
        store.commit("set_employer_check", data.employer);
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

watch(
  () => data.RoleChoose,
  () => {
    data.employer.role = data.RoleChoose == null ? "" : data.RoleChoose.id;
  }
);
watch(
  () => data.AddEmpolyerServicesSearch,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.AddEmpolyerServicesSearch != null)
      for (let i = 0; i < data.AddEmpolyerServicesSearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerServicesSearch[i].id);
      }
    if (data.employer.services != null)
      for (let i = 0; i < data.employer.services.length; i++) {
        await checkIDs.push(data.employer.services[i].id);
      }

    data.employer.new_service_ids = [];
    data.employer.delete_service_ids = [];

    for (let i = 0; i < tmpIDs.length; i++) {
      if (checkIDs.indexOf(tmpIDs[i]) == -1) {
        await data.employer.new_service_ids.push(tmpIDs[i]);
      }
    }

    for (let i = 0; i < checkIDs.length; i++) {
      if (
        tmpIDs.indexOf(checkIDs[i]) == -1 &&
        data.employer.new_service_ids.indexOf(checkIDs[i]) == -1
      ) {
        await data.employer.delete_service_ids.push(checkIDs[i]);
      }
    }
  }
);
</script>

<template>
  <Toast />
  <TabMenu class="p-mb-3" :model="data.items" v-model:activeIndex="data.active">
  </TabMenu>
  <div v-if="data.active == 0" class="p-grid">
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.ID.Show
        }}</label>
        :
        <span>{{ data.employer.id }}</span>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.UserName.Show
        }}</label>
        :
        <span>{{ data.employer.username }}</span>
      </div>
      <div
        class="p-field"
        v-if="
          store.state.user.role == 1 || store.state.user.id == data.employer.id
        "
      >
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.PassWord.Show
        }}</label>
        :
        <InputText
          v-model="data.employer.password"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
        <Button
          :label="i18n.$t('Employer').Save"
          class="p-button-success p-button-sm p-ml-1"
          @click="EditEmployer"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Role.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer.role == 1
            ? "Admin"
            : data.employer.role == 3
            ? "Employer"
            : "Unknow"
        }}</span>
        <Dropdown
          v-else
          v-model="data.RoleChoose"
          :options="data.RoleList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Role.Show"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Name.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{ data.employer.name }}</span>
        <InputText
          v-else
          v-model="data.employer.name"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.EnglishName.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer.english_name
        }}</span>
        <InputText
          v-else
          v-model="data.employer.english_name"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.PositionName.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer.position_name
        }}</span>
        <InputText
          v-else
          v-model.trim="data.employer.position_name"
          required="true"
        />
        <!-- <InputText class="p-ml-1 p-inputtext-sm" id="name" required="true" /> -->
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Service.Show
        }}</label>
        :
        <MultiSelect
          v-model="data.AddEmpolyerServicesSearch"
          :options="data.selectServicesList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Service.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Service.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ContractImage.Show
        }}</label> -->
        <div v-if="data.employer.image_url != ''">
          <Button
            :label="i18n.$t('Employer').EmployerTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger p-mb-2"
            @click="data.employer.image_url = ''"
          /><br />
          <Avatar
            shape="circle"
            :image="data.employer.image_url"
            size="xlarge"
          />
          <!-- <img :src="data.employer.image_url" style="width: 80%" /> -->
        </div>
        <FileUpload
          v-if="data.employer.image_url == ''"
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Employer').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Employer').FileLoad.cancelLabel"
          @uploader="onUploadImage"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Employer").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
    </div>
  </div>
  <div v-if="data.active == 1" class="p-grid">
    <div class="p-col-12">
      <Shift />
    </div>
  </div>
  <!-- <div v-if="data.active == 2" class="p-grid"></div> -->
  <div
    v-if="store.state.user.role == 1 && data.active == 0"
    class="p-col-12 p-text-center"
  >
    <Button
      :label="i18n.$t('Employer').EmployerTable.Complete"
      class="p-button-success p-button-sm"
      @click="AddEmployer"
    />
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
