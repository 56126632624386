<script setup>
import { onMounted } from "vue";
import { provideI18n } from "./i18nPlugin";
import NavBar from "./components/NavBar.vue";
import cn from "./i18n/cn";
import en from "./i18n/en";
import jp from "./i18n/jp";
import cookie from "./cookie";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Login from "./views/Login.vue";
import User from "./api/salary/auth/user";
import firebase from "firebase";
import router from "./router";
let userApi = new User();

const toast = useToast();
let cookieSetting = new cookie();
let lang = cookieSetting.getCookie("lang");
const store = useStore();

if (lang != "") {
  store.commit("set_lang", lang);
}
// else {
//   store.commit("set_lang", "jp");
// }

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // router.push("/");
      store.commit("set_user", null);
    }
  });
}

const mounted = onMounted(async () => {
  if (
    router.currentRoute.value.path != "/product-show" &&
    router.currentRoute.value.path != "/bot-order/" &&
    router.currentRoute.value.path != "/share" &&
    router.currentRoute.value.path != "/appointment-customer" &&
    router.currentRoute.value.path != "/appointmentlog-customer"
  ) {
    CheckUser();
  }
});

provideI18n({
  locale: store.state.lang,
  messages: {
    cn: cn,
    en: en,
    jp: jp,
  },
});
</script>

<template>
  <Login
    class="login"
    v-if="
      store.state.user == null &&
      router.currentRoute.value.path != '/product-show' &&
      router.currentRoute.value.path != '/bot-order/' &&
      router.currentRoute.value.path != '/share' &&
      router.currentRoute.value.path != '/appointment-customer' &&
      router.currentRoute.value.path != '/appointmentlog-customer'
    "
  />
  <div v-else>
    <NavBar
      v-if="
        store.state.user != null &&
        router.currentRoute.value.path != '/product-show' &&
        router.currentRoute.value.path != '/bot-order/' &&
        router.currentRoute.value.path != '/share' &&
        router.currentRoute.value.path != '/appointment-customer' &&
        router.currentRoute.value.path != '/appointmentlog-customer'
      "
    />
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<style>
.login {
  text-align: left;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
