import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class BlockList {
  getBlockListList(user_id, page, page_num) {
    return axios.get(
      `/api/admin/block-list/list`,
      {
        params: {
          user_id: user_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  addBlockList(user_ids, remark) {
    return axios.post(
      `/api/admin/block-list/`,
      {
        user_ids: user_ids,
        remark: remark,
      },
      config
    );
  }
  editBlockList(ids, remark) {
    return axios.put(
      `/api/admin/block-list/`,
      {
        ids: ids,
        remark: remark,
      },
      config
    );
  }
  deleteBlockList(id) {
    return axios.delete(
      `/api/admin/block-list`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
