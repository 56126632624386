import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Appointment {
  getAppointmentInfo(id) {
    return axios.get(
      `/api/admin/appointment/detail`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAppointmentList(
    name,
    start_time_begin,
    start_time_end,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/appointment/list`,
      {
        params: {
          name: name,
          start_time_begin: start_time_begin,
          start_time_end: start_time_end,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchAppointment(datas) {
    return axios.post(
      `/api/admin/appointment/batch`,
      {
        list: datas,
        shift_status: 2,
        shift_stage: 1,
      },
      config
    );
  }
  addAppointment(
    user_id,
    customer_phone,
    customer_name,
    source_of_appointment,
    status,
    start_time,
    remark,
    appointment_content
  ) {
    return axios.post(
      `/api/admin/appointment/`,
      {
        user_id: user_id,
        customer_phone: customer_phone,
        customer_name: customer_name,
        source_of_appointment: source_of_appointment,
        status: status,
        start_time: start_time,
        remark: remark,
        appointment_content: appointment_content,
      },
      config
    );
  }
  editAppointment(
    id,
    customer_phone,
    customer_name,
    source_of_appointment,
    status,
    start_time,
    remark,
    appointment_content
  ) {
    return axios.put(
      `/api/admin/appointment/`,
      {
        id: id,
        customer_phone: customer_phone,
        customer_name: customer_name,
        source_of_appointment: source_of_appointment,
        status: status,
        start_time: start_time,
        remark: remark,
        appointment_content: appointment_content,
      },
      config
    );
  }
  editAppointmentStatus(
    id,
    customer_name,
    source_of_appointment,
    status,
    start_time,
    remark,
    appointment_content
  ) {
    return axios.put(
      `/api/admin/appointment/review`,
      {
        id: id,
        customer_name: customer_name,
        source_of_appointment: source_of_appointment,
        status: status,
        start_time: start_time,
        remark: remark,
        appointment_content: appointment_content,
      },
      config
    );
  }
  deleteAppointment(id) {
    return axios.delete(
      `/api/admin/appointment`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
  deleteAppointmentContent(id) {
    return axios.delete(
      `/api/admin/appointment-content/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
