import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Appointment {
  addAppointment(
    customer_phone,
    customer_name,
    source_of_appointment,
    status,
    start_time,
    remark,
    appointment_content
  ) {
    return axios.post(
      `/line/user/appointment/`,
      {
        customer_phone: customer_phone,
        customer_name: customer_name,
        source_of_appointment: source_of_appointment,
        status: status,
        start_time: start_time,
        remark: remark,
        appointment_content: appointment_content,
      },
      config
    );
  }
  getAppointmentList(
    name,
    start_time_begin,
    start_time_end,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/line/user/appointment/list`,
      {
        params: {
          name: name,
          start_time_begin: start_time_begin,
          start_time_end: start_time_end,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
