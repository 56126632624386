import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Employer {
  getEmployerInfo(id) {
    return axios.get(
      `/api/admin/employer/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getEmployerFreeSelectList(start_time, end_time, service_id, status, stage) {
    return axios.get(
      `/api/admin/employer/have-free/select-list`,
      {
        params: {
          start_time: start_time,
          end_time: end_time,
          service_id: service_id,
          status: status,
          stage: stage,
        },
      },
      config
    );
  }
  getEmployerSelectList(name) {
    return axios.get(
      `/api/admin/employer/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  getEmployerList(id, name, status, page, page_num) {
    return axios.get(
      `/api/admin/employer/list`,
      {
        params: {
          id: id,
          name: name,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  //   batchEmployer(datas) {
  //     return axios.post(
  //       `/api/admin/batch/product`,
  //       {
  //         datas: datas,
  //       },
  //       config
  //     );
  //   }
  addEmployer(
    password,
    position_name,
    name,
    english_name,
    role,
    image,
    new_service_ids
  ) {
    return axios.post(
      `/api/admin/employer/`,
      {
        password: password,
        position_name: position_name,
        name: name,
        english_name: english_name,
        role: role,
        image: image,
        new_service_ids: new_service_ids,
        status: 2,
      },
      config
    );
  }
  editEmployer(
    id,
    position_name,
    name,
    english_name,
    role,
    image,
    new_service_ids,
    delete_service_ids
  ) {
    return axios.put(
      `/api/admin/employer/`,
      {
        id: id,
        position_name: position_name,
        name: name,
        english_name: english_name,
        role: role,
        image: image,
        new_service_ids: new_service_ids,
        delete_service_ids: delete_service_ids,
      },
      config
    );
  }
  editEmployerPassword(id, password) {
    return axios.put(
      `/api/admin/employer/`,
      {
        id: id,
        password: password,
      },
      config
    );
  }
  editEmployerStatus(id, status) {
    return axios.put(
      `/api/admin/employer/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  editEmployerShift(id, new_shifts, delete_shift_ids) {
    return axios.put(
      `/api/admin/employer/`,
      {
        id: id,
        new_shifts: new_shifts,
        delete_shift_ids: delete_shift_ids,
      },
      config
    );
  }
  editEmployerService(id, new_service_ids, delete_service_ids) {
    return axios.put(
      `/api/admin/employer/`,
      {
        id: id,
        new_service_ids: new_service_ids,
        delete_service_ids: delete_service_ids,
      },
      config
    );
  }
  //   deleteEmployer(id) {
  //     return axios.delete(
  //       `/api/admin/product`,
  //       {
  //         data: {
  //           ids: id,
  //         },
  //       },
  //       config
  //     );
  //   }
}
