import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class StoreInformation {
  getStoreInformationInfo(id) {
    return axios.get(
      `/api/admin/store-information/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getStoreInformationList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/store-information/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getStoreInformationSelectList(name) {
    return axios.get(
      `/api/admin/store-information/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchStoreInformation(datas) {
    return axios.post(
      `/api/admin/store-information/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addStoreInformation(
    name,
    atm_account_number,
    atm_bank_number,
    line_start_time,
    line_end_time,
    entity_open_time_description,
    physical_address,
    open_weekly
  ) {
    return axios.post(
      `/api/admin/store-information/`,
      {
        name: name,
        atm_account_number: atm_account_number,
        atm_bank_number: atm_bank_number,
        line_start_time: line_start_time,
        line_end_time: line_end_time,
        entity_open_time_description: entity_open_time_description,
        physical_address: physical_address,
        open_weekly: open_weekly,
      },
      config
    );
  }
  editStoreInformation(
    id,
    name,
    atm_account_number,
    atm_bank_number,
    line_start_time,
    line_end_time,
    entity_open_time_description,
    physical_address,
    open_weekly
  ) {
    return axios.put(
      `/api/admin/store-information/`,
      {
        id: id,
        name: name,
        atm_account_number: atm_account_number,
        atm_bank_number: atm_bank_number,
        line_start_time: line_start_time,
        line_end_time: line_end_time,
        entity_open_time_description: entity_open_time_description,
        physical_address: physical_address,
        open_weekly: open_weekly,
      },
      config
    );
  }
  deleteStoreInformation(id) {
    return axios.delete(
      `/api/admin/store-information/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
