import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Employer {
  getEmployerSelectList(name) {
    return axios.get(
      `/line/employer/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  getEmployerFreeSelectList(start_time, end_time, service_id) {
    return axios.get(
      `/line/employer/have-free/select-list`,
      {
        params: {
          start_time: start_time,
          end_time: end_time,
          service_id: service_id,
        },
      },
      config
    );
  }
  getEmployerShipRelations(employer_ids, start_date, end_date) {
    return axios.get(
      `/line/employer/shift-relations`,
      {
        params: {
          employer_ids: employer_ids,
          start_date: start_date,
          end_date: end_date,
        },
      },
      config
    );
  }
  getEmployerAppointmentList(id, start_time, end_time) {
    return axios.get(
      `/line/employer/appointment-list`,
      {
        params: {
          id: id,
          start_time: start_time,
          end_time: end_time,
        },
      },
      config
    );
  }
}
