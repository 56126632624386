import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Shift {
  getShiftInfo(id) {
    return axios.get(
      `/api/admin/shift/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getShiftSelectList(name) {
    return axios.get(
      `/api/admin/shift/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  getShiftList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/shift/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchShift(datas) {
    return axios.post(
      `/api/admin/shift/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addShift(name, remark, start_time, end_time) {
    return axios.post(
      `/api/admin/shift/`,
      {
        name: name,
        remark: remark,
        start_time: start_time,
        end_time: end_time,
      },
      config
    );
  }
  editShift(id, name, remark, start_time, end_time) {
    return axios.put(
      `/api/admin/shift/`,
      {
        id: id,
        name: name,
        remark: remark,
        start_time: start_time,
        end_time: end_time,
      },
      config
    );
  }
  deleteShift(id) {
    return axios.delete(
      `/api/admin/shift`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
