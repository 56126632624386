import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import "primeflex/primeflex.scss";
// import "primevue/resources/themes/vela-green/theme.css";
import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
import vue3JsonExcel from "vue3-json-excel";
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAX3SJ_gnpNvvsD6Naa8wd2n2HpcARdU4c",
  authDomain: "leyuafrica.firebaseapp.com",
  projectId: "leyuafrica",
  storageBucket: "leyuafrica.appspot.com",
  messagingSenderId: "721624802563",
  appId: "1:721624802563:web:e8bd55ec1db745877db536",
  measurementId: "G-S261HM59E9",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// import vueXlsxTable from "vue-xlsx-table";
// convertedData.body
createApp(App)
  .use(router)
  .use(store)
  .use(ToastService)
  .use(PrimeVue, {
    locale: {
      startsWith: "Starts with",
      contains: "Contains",
      notContains: "Not contains",
      endsWith: "Ends with",
      equals: "Equals",
      notEquals: "Not equals",
      noFilter: "No Filter",
      lt: "Less than",
      lte: "Less than or equal to",
      gt: "Greater than",
      gte: "Greater than or equal to",
      dateIs: "Date is",
      dateIsNot: "Date is not",
      dateBefore: "Date is before",
      dateAfter: "Date is after",
      clear: "Clear",
      apply: "Apply",
      matchAll: "Match All",
      matchAny: "Match Any",
      addRule: "Add Rule",
      removeRule: "Remove Rule",
      accept: "Yes",
      reject: "No",
      choose: "Choose",
      upload: "Upload",
      cancel: "Cancel",
      dayNames: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
      dayNamesShort: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
      dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
      monthNames: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      monthNamesShort: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      today: "Today",
      weekHeader: "Wk",
      firstDayOfWeek: 0,
      dateFormat: "mm/dd/yy",
      weak: "Weak",
      medium: "Medium",
      strong: "Strong",
      passwordPrompt: "Enter a password",
      emptyFilterMessage: "No results found",
      emptyMessage: "No available options",
    },
  })
  .use(vue3JsonExcel)
  .directive("tooltip", Tooltip)
  .mount("#app");
