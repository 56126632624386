import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "booking",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class LineUser {
  getLineUserInfo(id) {
    return axios.get(
      `/api/admin/line-user/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getLineUserList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/line-user/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getLineUserSelectList(name) {
    return axios.get(
      `/api/admin/line-user/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  editLineUser(
    id,
    name,
    remark,
    phone,
    new_add_sub_list_codes,
    delete_add_sub_list_codes
  ) {
    return axios.put(
      `/api/admin/line-user/`,
      {
        id: id,
        name: name,
        remark: remark,
        phone: phone,
        new_add_sub_list_codes: new_add_sub_list_codes,
        delete_add_sub_list_codes: delete_add_sub_list_codes,
      },
      config
    );
  }
  deleteLineUser(id) {
    return axios.delete(
      `/api/admin/line-user/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
  sendMsg(msg) {
    return axios.post(
      `/api/admin/line-user/broadcast`,
      {
        msg: msg,
      },
      config
    );
  }
}
