import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

// import User from "../views/user/User.vue";
import Product from "../views/Product.vue";
import ProductOrder from "../views/ProductOrder.vue";
// import ProductShow from "../views/ProductShow.vue";
import ProductShowCustomer from "../views/ProductShowCustomer.vue";
import Menu from "../views/Menu.vue";
import Share from "../views/Share.vue";

import StoreInfo from "../views/StoreInfo.vue";
import ProductType from "../views/ProductType.vue";
import LineUser from "../views/LineUser.vue";
import OrderLog from "../views/OrderLog.vue";
import ImageRegister from "../views/ImageRegister.vue";
import Sub from "../views/Sub.vue";
import SendMsg from "../views/SendMsg.vue";

import Employer from "../views/Employer.vue";
import Shift from "../views/Shift.vue";
import Service from "../views/Service.vue";
import EmployerShift from "../views/EmployerShift.vue";
import Appointment from "../views/Appointment.vue";
import AppointmentCustomer from "../views/AppointmentCustomer.vue";
import AppointmentLog from "../views/AppointmentLog.vue";
import AppointmentLogCustomer from "../views/AppointmentLogCustomer.vue";

const routes = [
  {
    path: "/appointmentlog-customer",
    name: "AppointmentLogCustomer",
    component: AppointmentLogCustomer,
  },
  {
    path: "/appointmentlog",
    name: "AppointmentLog",
    component: AppointmentLog,
  },
  {
    path: "/appointment-customer",
    name: "AppointmentCustomer",
    component: AppointmentCustomer,
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: Appointment,
  },
  {
    path: "/employer-shift",
    name: "EmployerShift",
    component: EmployerShift,
  },
  {
    path: "/employer",
    name: "Employer",
    component: Employer,
  },
  {
    path: "/shift",
    name: "Shift",
    component: Shift,
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
  },

  {
    path: "/share",
    name: "Share",
    component: Share,
  },
  {
    path: "/bot-order",
    name: "OrderLog",
    component: OrderLog,
  },
  {
    path: "/user",
    name: "LineUser",
    component: LineUser,
  },
  {
    path: "/msg",
    name: "SendMsg",
    component: SendMsg,
  },
  {
    path: "/product-type",
    name: "ProductType",
    component: ProductType,
  },
  {
    path: "/",
    name: "Home",
    component: StoreInfo,
  },
  {
    path: "/store-info",
    name: "StoreInfo",
    component: StoreInfo,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/image-register",
    name: "ImageRegister",
    component: ImageRegister,
  },
  {
    path: "/sub",
    name: "Sub",
    component: Sub,
  },
  {
    path: "/product-show",
    name: "ProductShowCustomer",
    component: ProductShowCustomer,
  },
  // {
  //   path: "/product-show",
  //   name: "Menu",
  //   component: Menu,
  // },
  {
    path: "/product-order",
    name: "ProductOrder",
    component: ProductOrder,
    children: [
      {
        path: "content", // url= shop/cart
        component: ProductOrder,
        // meta: { requiresAuth: false },
      },
      {
        path: "add", // url= shop/cart
        component: ProductOrder,
        // meta: { requiresAuth: false },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
