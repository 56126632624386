<script setup>
import Dropdown from "primevue/dropdown";
import { ref, reactive } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Toolbar from "primevue/toolbar";
import router from "../router";
import cookie from "../cookie";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import logo from "../assets/leyu-logo-w.png";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import { useToast } from "primevue/usetoast";

const toast = useToast();

let userApi = new User();

let cookieSetting = new cookie();
const i18n = useI18n();
const store = useStore();
defineProps({
  //   video_stream: String,
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function Logout() {
  userApi.logoutUser().then((res) => {
    if (res.data.error_code == 0) {
      CheckUser();
      routerSwitch("/");
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function langSwitch() {
  cookieSetting.doCookieSetup("lang", store.state.lang);
  i18n.locale.value = store.state.lang;
  location.reload();
}
function routerSwitch(url) {
  router.push(url);
}
const data = reactive({
  visibleLeft: false,
  lang: [
    { name: "中文", code: "cn" },
    { name: "English", code: "en" },
    { name: "やまと", code: "jp" },
  ],
  items:
    store.state.user == null
      ? []
      : store.state.user.role == 1
      ? [
          {
            label: i18n.$t("NavBar").StoreInfo.Label,
            link: i18n.$t("NavBar").StoreInfo.Link,
            logo: i18n.$t("NavBar").StoreInfo.Logo,
          },
          {
            label: i18n.$t("NavBar").Employer.Label,
            link: i18n.$t("NavBar").Employer.Link,
            logo: i18n.$t("NavBar").Employer.Logo,
          },
          {
            label: i18n.$t("NavBar").EmployerShift.Label,
            link: i18n.$t("NavBar").EmployerShift.Link,
            logo: i18n.$t("NavBar").EmployerShift.Logo,
          },
          {
            label: i18n.$t("NavBar").Appointment.Label,
            link: i18n.$t("NavBar").Appointment.Link,
            logo: i18n.$t("NavBar").Appointment.Logo,
          },
          {
            label: i18n.$t("NavBar").AppointmentLog.Label,
            link: i18n.$t("NavBar").AppointmentLog.Link,
            logo: i18n.$t("NavBar").AppointmentLog.Logo,
          },
          // {
          //   label: i18n.$t("NavBar").ProductType.Label,
          //   link: i18n.$t("NavBar").ProductType.Link,
          //   logo: i18n.$t("NavBar").ProductType.Logo,
          // },
          // {
          //   label: i18n.$t("NavBar").Product.Label,
          //   link: i18n.$t("NavBar").Product.Link,
          //   logo: i18n.$t("NavBar").Product.Logo,
          // },
          // {
          //   label: i18n.$t("NavBar").Sub.Label,
          //   link: i18n.$t("NavBar").Sub.Link,
          //   logo: i18n.$t("NavBar").Sub.Logo,
          // },
          // {
          //   label: i18n.$t("NavBar").ProductOrder.Label,
          //   link: i18n.$t("NavBar").ProductOrder.Link,
          //   logo: i18n.$t("NavBar").ProductOrder.Logo,
          // },
          {
            label: i18n.$t("NavBar").LineUser.Label,
            link: i18n.$t("NavBar").LineUser.Link,
            logo: i18n.$t("NavBar").LineUser.Logo,
          },
          {
            label: i18n.$t("NavBar").Msg.Label,
            link: i18n.$t("NavBar").Msg.Link,
            logo: i18n.$t("NavBar").Msg.Logo,
          },
          {
            label: i18n.$t("NavBar").Shift.Label,
            link: i18n.$t("NavBar").Shift.Link,
            logo: i18n.$t("NavBar").Shift.Logo,
          },
          {
            label: i18n.$t("NavBar").Service.Label,
            link: i18n.$t("NavBar").Service.Link,
            logo: i18n.$t("NavBar").Service.Logo,
          },
          // {
          //   label: i18n.$t("NavBar").ImageRegister.Label,
          //   link: i18n.$t("NavBar").ImageRegister.Link,
          //   logo: i18n.$t("NavBar").ImageRegister.Logo,
          // },
        ]
      : [],
});
</script>

<template>
  <Toolbar key="1" class="toolbar">
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        style="background: #1c0166 !important"
        @click="data.visibleLeft = true"
      />
      <img alt="logo" :src="logo" class="p-mr-2 logo p-ml-6" />
    </template>

    <template #end>
      <!-- <Dropdown
        v-model="store.state.lang"
        :options="data.lang"
        @change="langSwitch"
        optionLabel="name"
        optionValue="code"
      /> -->
      <Button
        class="p-button-danger p-ml-1"
        icon="pi pi-sign-out"
        iconPos="left"
        @click="Logout"
      ></Button>
    </template>
  </Toolbar>
  <Toolbar
    key="2"
    class="toolbar_detail p-d-none"
    style="
      padding-top: 8px !important;
      white-space: nowrap;
      overflow: scroll;
      z-index: 100;
    "
  >
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        @click="data.visibleLeft = true"
      />
      <!-- <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('/')"
      >
        {{ i18n.$t("Material").Title }}
      </div> -->
    </template>
  </Toolbar>
  <Sidebar v-model:visible="data.visibleLeft" position="left">
    <div class="p-grid">
      <Button
        :key="index"
        v-for="(item, index) in data.items"
        class="p-button-lg p-button-info p-col-12 p-button-text p-pt-2 p-pb-3"
        :icon="item.logo"
        :label="item.label"
        @click="routerSwitch(item.link)"
      />
    </div>
  </Sidebar>
</template>

<style lang="scss">
.button {
  width: auto;
  cursor: pointer;
  border: 2px dotted;
}
.button:hover {
  color: rgb(199, 6, 6);
  border-bottom: 0.5rem solid;
}
.toolbar_detail {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 50px;
  left: 0px;
  /* overflow: hidden; */
  border-top: none !important;
  background: rgb(252, 252, 252) !important;
  border-bottom: 0.1rem solid !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 85px;
  z-index: 0;
}
.toolbar {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 85px;
  left: 0px;
  /* overflow: hidden; */
  background: rgb(28, 1, 102) !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 0;
  z-index: 99;
  .logo {
    position: fixed; /* Set the navbar to fixed position */
    top: 5;
    height: 65px;
  }
}
</style>
